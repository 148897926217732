import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { Layout } from "../components/Layout";
import Seo from "../components/SEO";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StyledMarkdown } from "../components/StyledMarkdown";

const StyledPage = styled.article`
  max-width: 774px;
`;

const FeaturedImage = styled(GatsbyImage)`
  margin-top: 40px;
`;

const PageTemplate = ({ data }) => {
  const page = data.markdownRemark;
  const title = page.frontmatter.title;
  const featuredImage = page.frontmatter.featuredImage;

  return (
    <Layout>
      <Seo
        title={title}
        description={page.frontmatter.description || page.excerpt}
      />
      <StyledPage>
        {featuredImage && (
          <FeaturedImage image={getImage(featuredImage)} alt={title} />
        )}
        <StyledMarkdown
          dangerouslySetInnerHTML={{
            __html: page.html,
          }}
          itemProp="description"
        />
      </StyledPage>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY.")
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
